import { createSelector } from 'reselect';
import * as constants from './oloConfiguration.constants';

export const getConfiguration = (state) => state.getIn([constants.OLO_CONFIGURATION, constants.OLO_CONFIGURATION_DATA]);
export const getValidationErrors = (state) => state.getIn([constants.OLO_CONFIGURATION, constants.OLO_VALIDATION_ERROR]);
export const isFormInvalid = createSelector(getValidationErrors, hasErrors);
export const isLoading = (state) => state.getIn([constants.OLO_CONFIGURATION, constants.OLO_CONFIGURATION_DATA_LOADING]);
export const isDeleteLoading = (state) => state.getIn([constants.OLO_CONFIGURATION, constants.OLO_CONFIGURATION_DELETE_DATA_LOADING]);
export const isSaving = (state) => state.getIn([constants.OLO_CONFIGURATION, constants.SAVING]);
export const configurationSaved = (state) => state.getIn([constants.OLO_CONFIGURATION, constants.SAVED_CONFIGURATION]);
export const hasError = (state) => state.getIn([constants.OLO_CONFIGURATION, constants.ERROR]);
function hasErrors(validationErrors) {
  return validationErrors && validationErrors.some((value) => value !== undefined);
}
