import { fromJS } from 'immutable';
import * as constants from './oloConfiguration.constants';
import { validateURL } from './oloConfigurations.utils';

const initialState = fromJS({});

function oloConfigurationReducer(state = initialState, action) {
  let newState;

  switch (action.type) {
    case constants.SET_OLO_CONFIGURATION_DATA_IS_LOADING :
      newState = state.set(constants.OLO_CONFIGURATION_DATA_LOADING, action.isLoading);
      break;
    case constants.SET_OLO_CONFIGURATION_DELETE_DATA_IS_LOADING :
      newState = state.set(constants.OLO_CONFIGURATION_DELETE_DATA_LOADING, action.isLoading);
      break;
    case constants.GET_OLO_CONFIGURATION_SUCCESS : {
      const data = fromJS(action.data);
      const validationErrors = data.map((value, key) => {
        if (key !== constants.CLIENT_SECRET && key !== constants.CLIENT_NAME && key !== constants.CLIENT_ID && (value === null || value === undefined || value === '')) {
          return constants.OLO_FIELD_REQUIRED;
        }
        return undefined;
      });
      newState = state.set(constants.OLO_CONFIGURATION_DATA, data).set(constants.OLO_VALIDATION_ERROR, validationErrors);
      break;
    }
    case constants.ON_OLO_CONFIGURATION_FIELD_CHANGE: {
      const { field, value, validateUrl } = action.payload;
      const isValid = validateUrl ? validateURL(value) : true;
      newState = state.setIn([constants.OLO_VALIDATION_ERROR, field], isValid ? undefined : constants.OLO_URL_VALIDATION_ERROR).setIn([constants.OLO_CONFIGURATION_DATA, field], value);
      break;
    }
    case constants.ON_REDIRECT_URI_FIELD_CHANGE: {
      const { index, uri } = action.payload;
      if (uri === null) {
        newState = state.updateIn([constants.OLO_CONFIGURATION_DATA, constants.REDIRECT_URIS], (list) => list.delete(index));
      } else {
        newState = state.setIn([constants.OLO_CONFIGURATION_DATA, constants.REDIRECT_URIS, index], uri);
      }
      break;
    }
    case constants.ON_ADD_REDIRECT_URI_FIELD: {
      newState = state.updateIn([constants.OLO_CONFIGURATION_DATA, constants.REDIRECT_URIS], (list) => list.push(''));
      break;
    }
    case constants.SAVING_CONFIGURATION:
      newState = state.set(constants.SAVING, true);
      break;
    case constants.CONFIGURATION_SAVE_SUCCESS:
      newState = state.set(constants.SAVING, false)
        .set(constants.SAVED_CONFIGURATION, true);
      break;
    case constants.DELETE_SECRET:
      newState = state.setIn([constants.OLO_CONFIGURATION_DATA, constants.CLIENT_SECRET], undefined);
      break;
    case constants.CLOSE_SAVED_CONFIGURATION_POPUP_MESSAGE:
      newState = state.set(constants.SAVED_CONFIGURATION, false);
      break;
    case constants.ERROR_OCCURRED:
      newState = state.set(constants.ERROR, true);
      break;
    default:
      newState = state;
  }

  return newState;
}

export default oloConfigurationReducer;
