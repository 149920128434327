import { fromJS, List } from 'immutable';

import * as schemaConstants from '../../activitySchema/activitySchema.constants';
import * as constants from '../../activity.constants';
import * as defaults from '../activity.reducer.defaults';
import * as datesConstants from '../../../../common/utils/dateTime.constants';
import { adjustConditions } from './condition.reducer.utils';

/**
 * update tempMembershipStatusCondition value according to a newly checked/unchecked one
 * @param oldValue
 * @param newValue
 * @returns {*|Immutable.List<any>}
 */
export function mergeMembershipStatusValues(oldValue, newValue) {
  let returnValue = oldValue;
  // only if new value not in List it was checked and should be added
  if (oldValue.findIndex((val) => val === newValue) === -1) {
    returnValue = returnValue.push(fromJS(newValue));
  } else {
    returnValue = returnValue.delete(oldValue.findIndex((val) => val === newValue));
  }
  return returnValue;
}

export function getDefaultUpdateConditionValueByDataValueType(dataValueType) {
  switch (dataValueType) {
    case schemaConstants.DATA_VALUE_TYPE_BOOLEAN: {
      return constants.BOOLEAN_VALUE_FALSE;
    }
    default:
      return null;
  }
}

export function normalizeGlobalOccurrencesLimitConditionTempModel(tempConditionModel) {
  let result = tempConditionModel;
  const mode = result.get(constants.LIMIT_OCCURRENCES_PER_MEMBER_CONDITION_TYPE);
  const infiniteMode = mode === constants.INFINITE;
  const byPeriodConditionEnabled = !infiniteMode && result.get(constants.BY_PERIOD_CONDITION_ENABLED);
  const totalOccurrencesConditionEnabled = !infiniteMode && result.get(constants.TOTAL_OCCURRENCES_CONDITION_ENABLED);
  if (!byPeriodConditionEnabled) {
    result = result
      .set(constants.BY_PERIOD_CONDITION_ENABLED, false)
      .set(constants.BY_PERIOD_CONDITION, null);
  }
  if (!totalOccurrencesConditionEnabled) {
    result = result
      .set(constants.TOTAL_OCCURRENCES_CONDITION_ENABLED, false)
      .set(constants.TOTAL_OCCURRENCES_CONDITION, null);
  }
  const isRollingPeriod = result.getIn([constants.BY_PERIOD_CONDITION, constants.CONDITION_VALUE, constants.IS_ROLLING_PERIOD]);
  if (!isRollingPeriod && result.getIn([constants.BY_PERIOD_CONDITION, constants.CONDITION_VALUE, constants.BY_PERIOD_PERIOD_MULTIPLIER])) {
    result = result.setIn([constants.BY_PERIOD_CONDITION, constants.CONDITION_VALUE, constants.BY_PERIOD_PERIOD_MULTIPLIER], null);
  }
  return result;
}

export function normalizeMembershipGlobalConditionTempModel(membershipConditionsModel) {
  if (membershipConditionsModel.get(constants.TYPE) === constants.MEMBERS_REGISTERED
      || membershipConditionsModel.get(constants.TYPE) === constants.NON_MEMBERS
      || membershipConditionsModel.get(constants.TYPE) === constants.ALL_COSTUMERS) {
    return membershipConditionsModel
      .delete(constants.CONDITIONS)
      .delete(constants.USER_ACTIONS_CONDITIONS)
      .delete(constants.MEMBERSHIP_STATUS_CONDITION)
      .delete(constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER)
      .set(constants.MEMBERSHIP_STATUS_CONDITION, fromJS(defaults.defaultMembershipStatusCondition));
  } else if (membershipConditionsModel.get(constants.TYPE) === constants.MEMBERS_IMPORT
      || membershipConditionsModel.get(constants.TYPE) === constants.MEMBERS_PHONENUMBER_IMPORT) {
    return membershipConditionsModel
      .delete(constants.CONDITIONS)
      .delete(constants.USER_ACTIONS_CONDITIONS)
      .delete(constants.MEMBERSHIP_STATUS_CONDITION);
  }
  return membershipConditionsModel.delete(constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER);
}

export function addGlobalMembershipCondition(conditionsList) {
  return conditionsList.push(fromJS(defaults.emptyCondition));
}

export function removeGlobalMembershipCondition(conditionsList, conditionIndex) {
  const result = conditionsList.filter((condition, index) => index !== conditionIndex);
  if (result.size === 0) {
    return result.set(0, fromJS(defaults.emptyCondition));
  }
  return result;
}

export function updateLimitPerMemberGlobalConditionTotalLimitEnabled(globalCondition, isEnabled, activityType) {
  if (isEnabled && !globalCondition.get(constants.TOTAL_OCCURRENCES_CONDITION)) {
    const conditionValue = activityType === schemaConstants.TRIGGER_DEAL ? defaults.defaultDealTotalOccurrencesConditionValue : defaults.defaultTotalOccurrencesConditionValue;
    return globalCondition
      .set(constants.TOTAL_OCCURRENCES_CONDITION, fromJS(conditionValue))
      .set(constants.TOTAL_OCCURRENCES_CONDITION_ENABLED, true);
  }
  return globalCondition.set(constants.TOTAL_OCCURRENCES_CONDITION_ENABLED, isEnabled);
}

export function updateLimitPerMemberGlobalConditionByPeriodLimitEnable(globalCondition, isEnabled, activityType) {
  if (isEnabled && !globalCondition.get(constants.BY_PERIOD_CONDITION)) {
    const conditionValue = activityType === schemaConstants.TRIGGER_DEAL ? defaults.defaultDealByPeriodLimitConditionValue : defaults.defaultByPeriodLimitConditionValue;
    return globalCondition
      .set(constants.BY_PERIOD_CONDITION, fromJS(conditionValue))
      .set(constants.BY_PERIOD_CONDITION_ENABLED, true);
  }
  return globalCondition.set(constants.BY_PERIOD_CONDITION_ENABLED, isEnabled);
}

export function updateLimitPerMemberGlobalConditionByPeriodUnitChange(globalCondition, value) {
  const conditionValuePath = [constants.BY_PERIOD_CONDITION, constants.CONDITION_VALUE];
  const isRollingPeriod = value === constants.ROLLING_PERIOD;
  if (isRollingPeriod) {
    return globalCondition
      .setIn(conditionValuePath.concat(constants.IS_ROLLING_PERIOD), true)
      .setIn(conditionValuePath.concat(constants.BY_PERIOD_CONDITION_PERIOD), datesConstants.HOUR);
  }
  return globalCondition
    .setIn(conditionValuePath.concat(constants.IS_ROLLING_PERIOD), false)
    .setIn(conditionValuePath.concat(constants.BY_PERIOD_CONDITION_PERIOD), value);
}

export function updateMembershipTempDataField(tempData, fieldName, fieldValue) {
  const result = tempData.set(fieldName, fromJS(fieldValue));
  const conditionValue = tempData.get(constants.CONDITION_VALUE);
  if (fieldName === constants.SCHEMA_CONDITION_VALUE_DATA_TYPE
    && (conditionValue === null || typeof conditionValue === 'undefined')) {
    const conditionValueDataType = result.get(constants.SCHEMA_CONDITION_VALUE_DATA_TYPE);
    const defaultConditionValue = getDefaultUpdateConditionValueByDataValueType(conditionValueDataType);
    if (defaultConditionValue !== null) {
      return result.set(constants.CONDITION_VALUE, defaultConditionValue);
    }
  }
  return result;
}

export function getStatusConditionForTempData(membershipStatusCondition) {
  return membershipStatusCondition || fromJS(defaults.defaultMembershipStatusCondition);
}

export function getGlobalConditionPath(conditionParent, conditionIndex, userActionIndex) {
  switch (conditionParent) {
    case constants.FILTERS_USER_ACTION:
      return [constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS,
        constants.USER_ACTIONS_CONDITIONS_LIST, userActionIndex, constants.CONDITIONS,
        constants.CONDITIONS_LIST, conditionIndex];
    case constants.TEMP_REFERRAL_CODE_MODEL:
      return [constants.TEMP_REFERRAL_CODE_MODEL];
    default:
      return [constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.CONDITIONS, constants.CONDITIONS_LIST, conditionIndex];
  }
}

export function prepareReferralCodeTempConditionToSave(tempModel) {
  let condition = tempModel;
  if (tempModel.getIn([constants.CONDITION_VALUE, constants.CONDITION_VALUE_REFERRAL_DATA_TYPE]) !== constants.REFERRAL_CODE_TYPE_SPECIFIC) {
    condition = tempModel.setIn([constants.CONDITION_VALUE, constants.CONDITION_VALUE_REFERRAL_CODE], List());
  } else if (tempModel.getIn([constants.CONDITION_VALUE, constants.CONDITION_VALUE_REFERRAL_DATA_TYPE]) === constants.REFERRAL_CODE_TYPE_SPECIFIC) {
    condition = tempModel.setIn([constants.CONDITION_VALUE, constants.CONDITION_VALUE_IS_REFERRED], fromJS(true));
  }
  return condition;
}

export function alterMembershipGlobalConditionToIncludeUnregisteredStatus(membershipGlobalCondition) {
  if (membershipGlobalCondition.get(constants.TYPE) === constants.MEMBERS_REGISTERED) {
    return membershipGlobalCondition
      .set(constants.TYPE, constants.MEMBERS_SPECIFIC)
      .setIn([constants.MEMBERSHIP_STATUS_CONDITION, constants.CONDITION_VALUE],
        fromJS([schemaConstants.MEMBERSHIP_STATUS_VALUE_UNREGISTERED]))
      .set(constants.CONDITIONS, fromJS(defaults.emptyGlobalMembershipConditionsConditions));
  }
  const currentMembershipStatusConditionValue =
    membershipGlobalCondition.getIn([constants.MEMBERSHIP_STATUS_CONDITION, constants.CONDITION_VALUE]);
  if (currentMembershipStatusConditionValue.includes(schemaConstants.MEMBERSHIP_STATUS_VALUE_UNREGISTERED)) {
    return membershipGlobalCondition;
  }
  return membershipGlobalCondition.setIn(
    [constants.MEMBERSHIP_STATUS_CONDITION, constants.CONDITION_VALUE],
    currentMembershipStatusConditionValue.concat(schemaConstants.MEMBERSHIP_STATUS_VALUE_UNREGISTERED)
  );
}

export function prepareDateAttributeModel(conditionSchema) {
  return fromJS(defaults.emptyCondition)
    .set(constants.CONDITION_KEY, conditionSchema.get(constants.SCHEMA_CONDITION_KEY))
    .set(constants.OPERATOR_KEY, datesConstants.DATE_TIME_IS_BEFORE_OR_EQUAL)
    .set(constants.CONDITION_VALUE_DATA_TYPE, conditionSchema.get(constants.SCHEMA_CONDITION_VALUE_DATA_TYPE))
    .delete(constants.CONDITION_VALUE);
}

export function createEmptyUserActionAggregationConditions(schemaAggregatedConditionsPerUserAction) {
  return schemaAggregatedConditionsPerUserAction && schemaAggregatedConditionsPerUserAction.map((conditionSchema) =>
    fromJS(defaults.userActionAggregationEmptyConditionWrapper)
      .setIn([constants.CONDITION, constants.CONDITION_KEY], conditionSchema.get(constants.SCHEMA_CONDITION_KEY))
      .setIn([constants.CONDITION, constants.OPERATOR_KEY], conditionSchema.getIn([constants.SCHEMA_CONDITION_OPERATORS, 0, constants.VALUE]))
      .setIn([constants.CONDITION, constants.CONDITION_VALUE_DATA_TYPE], conditionSchema.get(constants.SCHEMA_CONDITION_VALUE_DATA_TYPE)));
}

export function initUserActionsConditions(userActionsConditions, userActionType, userActionIndex, dateRangeCondition, schemaAggregatedConditionsPerUserAction) {

  let newUserActionsConditions = userActionsConditions;

  // if no user actions conditions set empty user actions conditions with empty conditions list
  if (!newUserActionsConditions) {
    newUserActionsConditions = newUserActionsConditions.set(fromJS(defaults.userActionsEmptyFilters));
  }
  const aggregationConditions = createEmptyUserActionAggregationConditions(schemaAggregatedConditionsPerUserAction);
  // set new action type and default dateRange condition to the condition
  const userAction = fromJS(defaults.userActionEmptyConditions)
    .set(constants.USER_ACTION_KEY, userActionType)
    .set(constants.USER_ACTION_DATE_RANGE_CONDITION, dateRangeCondition)
    .set(constants.USER_ACTION_AGGREGATION_CONDITIONS, aggregationConditions);

  newUserActionsConditions = newUserActionsConditions.setIn([constants.USER_ACTIONS_CONDITIONS_LIST, userActionIndex], userAction);

  // if this the last user action => add a new empty one
  if (userAction === newUserActionsConditions.get(constants.USER_ACTIONS_CONDITIONS_LIST).last()) {
    newUserActionsConditions = newUserActionsConditions.setIn([constants.USER_ACTIONS_CONDITIONS_LIST, userActionIndex + 1], fromJS(defaults.userActionEmptyConditions));
  }
  return newUserActionsConditions;
}

export function mergeAllFilterConditions(filterConditions) {
  let mergedConditions = [];
  const conditions = filterConditions[constants.CONDITIONS];
  let conditionsOperator = constants.CONDITION_AND;
  if (conditions && conditions[constants.CONDITIONS_LIST]) {
    const cleanConditions = adjustConditions(conditions[constants.CONDITIONS_LIST]);
    mergedConditions = mergedConditions.concat(cleanConditions);
    if (conditions[constants.CONDITION_LIST_OPERATOR]) {
      conditionsOperator = conditions[constants.CONDITION_LIST_OPERATOR];
    }
  }

  const statusCondition = filterConditions[constants.MEMBERSHIP_STATUS_CONDITION];
  if (statusCondition) {
    mergedConditions.push(statusCondition);
  }

  const importMembersConditionWrapper = filterConditions[constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER];
  if (importMembersConditionWrapper) {
    const importMembersConditionMode = importMembersConditionWrapper[constants.IMPORT_MEMBERS_MODE];
    const importMembersCondition = importMembersConditionMode === constants.IMPORT_MEMBERS_COPY_PASTE_MODE
      ? importMembersConditionWrapper[constants.IMPORT_MEMBERS_COPY_PASTE_MODE]
      : importMembersConditionWrapper[constants.IMPORT_MEMBERS_UPLOAD_FILE_MODE];
    if (importMembersCondition) {
      const cleanImportCondition = adjustConditions([importMembersCondition])[0];
      mergedConditions.push(cleanImportCondition);
    }
  }
  return {
    [constants.CONDITION_LIST_OPERATOR]: conditionsOperator,
    [constants.CONDITIONS_LIST]: mergedConditions
  };
}

/**
 * Remove empty user action filters (no user action chosen) ans from each user action, remove empty conditions
 * @param filterConditions
 * @returns {*}
 */
export function adjustUserActionsConditions(filterConditions) {
  const userActionsConditions = filterConditions[constants.USER_ACTIONS_CONDITIONS];
  if (!userActionsConditions) return null;
  const userActionsConditionsList = userActionsConditions[constants.USER_ACTIONS_CONDITIONS_LIST];
  if (!userActionsConditionsList || userActionsConditionsList.length === 0) return null;
  const adjustedConditionList = userActionsConditionsList.map((userActionConditions) => {
    userActionConditions[constants.CONDITIONS][constants.CONDITIONS_LIST] = adjustConditions(userActionConditions[constants.CONDITIONS][constants.CONDITIONS_LIST]);
    // remove infinite datetime condition
    if (userActionConditions[constants.USER_ACTION_KEY] !== '' && userActionConditions[constants.USER_ACTION_DATE_RANGE_CONDITION][constants.OPERATOR_KEY] === datesConstants.DATE_TIME_IS_INFINITE) {
      delete userActionConditions[constants.USER_ACTION_DATE_RANGE_CONDITION];
    }
    return userActionConditions;
  }).filter((userActionConditions) =>
    userActionConditions[constants.USER_ACTION_KEY] !== ''
  );
  if (adjustedConditionList.length === 0) {
    delete userActionsConditions[constants.USER_ACTIONS_CONDITIONS_LIST];
  } else {
    userActionsConditions[constants.USER_ACTIONS_CONDITIONS_LIST] = adjustedConditionList;
  }
  return userActionsConditions;
}

export function getOneTimeReFilteringCommunicationModel(actions, conditions) {
  const emailActionPresent = actions.filter((action) => action.get(constants.ACTION_TYPE) === schemaConstants.SEND_MEMBER_EMAIL_ACTION).size > 0;
  const smsActionPresent = actions.filter((action) => action.get(constants.ACTION_TYPE) === schemaConstants.SEND_MEMBER_SMS_ACTION).size > 0;
  const pushActionPresent = actions.filter((action) => action.get(constants.ACTION_TYPE) === schemaConstants.SEND_PERSONAL_PUSH_ACTION).size > 0;

  const isEmailAllowedGlobalConditionNotApplied = conditions.filter((condition) => condition.get(constants.CONDITION_KEY) === schemaConstants.CONTEXT_MEMBERSHIP_ALLOW_EMAIL).size === 0;
  const isSMSAllowedGlobalConditionNotApplied = conditions.filter((condition) => condition.get(constants.CONDITION_KEY) === schemaConstants.CONTEXT_MEMBERSHIP_ALLOW_SMS).size === 0;
  const isPushAllowedGlobalConditionNotApplied = conditions.filter((condition) => condition.get(constants.CONDITION_KEY) === schemaConstants.CONTEXT_MEMBERSHIP_PUSH_NOTIFICATION_ENABLED).size === 0;
  const isConsentConditionAlreadyNotApplied = conditions.filter((condition) => condition.get(constants.CONDITION_KEY) === schemaConstants.CONTEXT_MEMBERSHIP_HAS_CONSENT).size === 0;

  return {
    shouldEmailAllowedGlobalConditionBeApplied: emailActionPresent && isEmailAllowedGlobalConditionNotApplied,
    shouldSMSAllowedGlobalConditionBeApplied: smsActionPresent && isSMSAllowedGlobalConditionNotApplied,
    shouldPushNotificationGlobalConditionBeApplied: pushActionPresent && isPushAllowedGlobalConditionNotApplied,
    shouldConsentGlobalConditionBeApplied: (emailActionPresent || smsActionPresent || pushActionPresent) && isConsentConditionAlreadyNotApplied
  };
}
