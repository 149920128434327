import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import PropTypes from 'prop-types';
import CopyToClipboard from '@como/web-hub-components/src/CopyToClipboard/InputWithCopyToClipboard';
import { CustomModal, Toast } from '@como/web-hub-components';
import ErrorMessage from '@como/web-hub-components/src/ErrorMessage/ErrorMessage';
import * as selector from './oloConfiguration.selectors';
import * as actions from '../OloConfiguration/oloConfiguration.actions';
import injectSaga from '../../utils/injectSaga';
import * as reducerConstants from '../../constants/reducer.constants';
import saga from '../OloConfiguration/oloConfiguraiton.saga';
import withAuthorization from '../../common/helpers/authorization';
import OloConfigurations from './views/OloConfigurations';
import './olo.style.less';
import '../Activity/activity.styles.less';
import '../PointShopItem/pointShopItem.styles.less';
import * as constants from './oloConfiguration.constants';

const automationIdPrefix = 'olo-configuration';

class OloConfiguration extends React.PureComponent {

  // eslint-disable-next-line no-undef
  static propTypes = {
    oloConfigurationData: PropTypes.object,
    validationErrors: PropTypes.object,
    actions: PropTypes.object,
    isLoading: PropTypes.bool,
    isDeleteLoading: PropTypes.bool,
    configurationsSaved: PropTypes.bool,
    validForm: PropTypes.bool,
    isSaving: PropTypes.bool,
    hasError: PropTypes.bool,
    intl: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.state = {
      showSecretPopup: false,
    };
    this.closeSecretPopup = this.closeSecretPopup.bind(this);
  }
  closeSecretPopup() {
    this.props.actions.deleteSecret();
    this.setState({ showSecretPopup: false });
  }
  componentDidMount() {
    if (!this.props.oloConfigurationData) {
      this.props.actions.getConfiguration();
    }
  }

  render() {
    const { formatMessage } = this.props.intl;
    if (!this.props.oloConfigurationData) {
      return (<Loader active={!this.props.oloConfigurationData} />);
    } else if (!this.props.oloConfigurationData) {
      return (<ErrorMessage
        showError={this.props.hasError}
        errorTitle={formatMessage({ id: 'business.communication.data.error' })}
        errorMessage={formatMessage({ id: 'getCustomScreensData.Fail' })}
        automationIdPrefix={`${automationIdPrefix}.error`}
      />);
    }
    if (this.props.oloConfigurationData.get(constants.CLIENT_SECRET)) {
      this.setState({ showSecretPopup: true });
    }
    return (
      <React.Fragment>
        <div className="settings-section">
          <div className="section-header">
            {formatMessage({ id: `${automationIdPrefix}.heading` })}
          </div>

          <div className="section-header">
            {this.props.oloConfigurationData.get(constants.CLIENT_ID) && <div style={{ width: '50%' }}>
              {formatMessage({ id: `${automationIdPrefix}.client-id` })}: {this.props.oloConfigurationData.get(constants.CLIENT_ID)}
            </div>}
            {this.props.oloConfigurationData.get(constants.CLIENT_NAME) && <div>
              {formatMessage({ id: `${automationIdPrefix}.client-name` })}: {this.props.oloConfigurationData.get(constants.CLIENT_NAME)}
            </div>}
          </div>

          <OloConfigurations
            oloConfigurationData={this.props.oloConfigurationData}
            validationErrors={this.props.validationErrors}
            formatMessage={formatMessage}
            automationIdPrefix={automationIdPrefix}
            onFieldChange={this.props.actions.configurationFieldChange}
          />
        </div>
        <div className={'save-button-container'}>
          <Button
            key="general.button.save"
            className={'save-button'}
            data-automation-id={`${automationIdPrefix}.save.button`}
            onClick={() => this.props.actions.saveConfiguration(this.props.oloConfigurationData)}
            disabled={this.props.isSaving || !this.props.validForm}
            loading={this.props.isLoading}
          >
            {formatMessage({ id: 'general.button.save' })}
          </Button>
        </div>
        <div className={'save-button-container'}>
          <Button
            key="general.button.delete"
            className={'delete-button'}
            data-automation-id={`${automationIdPrefix}.delete.button`}
            onClick={this.props.actions.deleteConfiguration}
            disabled={this.props.isSaving}
            loading={this.props.isDeleteLoading}
          >
            {formatMessage({ id: 'general.button.delete' })}
          </Button>
        </div>
        <CustomModal
          open={this.state.showSecretPopup}
          className={'como-dialog settings-dialog'}
          automationIdPrefix={`${automationIdPrefix}.modal.save`}
          header={
            <div className="type-and-name">
              {formatMessage({ id: `${automationIdPrefix}.modal.header` })}
            </div>
          }
          content={
            <div className={`${automationIdPrefix}.-modal-detail`}>
              <CopyToClipboard
                propertyName="apiKey"
                value={this.props.oloConfigurationData.get(constants.CLIENT_SECRET)}
                defValue={this.props.oloConfigurationData.get(constants.CLIENT_SECRET)}
                labelKey={`${automationIdPrefix}.secret.label`}
                disabled
              />
              <p>{formatMessage({ id: `${automationIdPrefix}.secret.note` })}</p>
            </div>
          }
          actions={[
            <Button
              key="save"
              data-automation-id={'settings.social.close'}
              onClick={this.closeSecretPopup}
            >
              {formatMessage({ id: 'general.button.close' })}
            </Button>
          ]}
          closeOnDimmerClick
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  oloConfigurationData: selector.getConfiguration(state),
  validationErrors: selector.getValidationErrors(state),
  validForm: !selector.isFormInvalid(state),
  isLoading: selector.isLoading(state),
  isDeleteLoading: selector.isDeleteLoading(state),
  isSaving: selector.isSaving(state),
  configurationsSaved: selector.configurationSaved(state),
  hasError: selector.hasError(state),
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

const withSaga = injectSaga({ key: reducerConstants.OLO_CONFIGURATION_BRANCH, saga });

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withSaga,
  withConnect,
  withAuthorization,
)(injectIntl(OloConfiguration));
