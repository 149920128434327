import axios from 'axios';

export function getConfiguration() {
  return axios.get('/api/Olo')
    .then((res) => res.data);
}
export function deleteConfiguration() {
  return axios.delete('/api/Olo')
    .then((res) => res.data);
}

export function saveConfiguration(data) {
  return axios.post('/api/Olo/saveConfiguration', data)
    .then((res) => res.data);
}
