import * as constants from '../OloConfiguration/oloConfiguration.constants';


export const getConfiguration = () => ({ type: constants.GET_CONFIGURATION });
export const deleteConfiguration = () => ({ type: constants.DELETE_CONFIGURATION });

export const configurationFieldChange = (field, value, validateUrl = false) => ({
  type: constants.ON_OLO_CONFIGURATION_FIELD_CHANGE,
  payload: { field, value, validateUrl }
});

export const redirectUrIFieldChange = (index, uri) => ({
  type: constants.ON_REDIRECT_URI_FIELD_CHANGE,
  payload: { index, uri }
});

export const addRedirectUrIField = () => ({
  type: constants.ON_ADD_REDIRECT_URI_FIELD,
});

export const saveConfiguration = (oloConfiguration) => ({
  type: constants.SAVE_CONFIGURATION,
  payload: oloConfiguration
});

export const closeSavedConfigurationPopupMessage = () => ({
  type: constants.CLOSE_SAVED_CONFIGURATION_POPUP_MESSAGE
});

export const deleteSecret = () => ({
  type: constants.DELETE_SECRET
});

