import { put, call, takeLatest } from 'redux-saga/effects';
import * as api from './oloConfiguration.api';
import * as constants from './oloConfiguration.constants';

function* getOloConfiguration() {
  try {
    yield put({ type: constants.SET_OLO_CONFIGURATION_DATA_IS_LOADING, isLoading: true });
    const data = yield call(api.getConfiguration);
    yield put({ type: constants.SET_OLO_CONFIGURATION_DATA_IS_LOADING, isLoading: false });
    yield put({ type: constants.GET_OLO_CONFIGURATION_SUCCESS, data });
    return data;
  } catch (err) {
    yield put({ type: constants.SET_OLO_CONFIGURATION_DATA_IS_LOADING, isLoading: false });
    yield put({ type: constants.ERROR_OCCURRED });
    return err;
  }
}

function* deleteOloConfiguration() {
  try {
    yield put({ type: constants.SET_OLO_CONFIGURATION_DELETE_DATA_IS_LOADING, isLoading: true });
    const data = yield call(api.deleteConfiguration);
    yield put({ type: constants.SET_OLO_CONFIGURATION_DELETE_DATA_IS_LOADING, isLoading: false });
    yield put({ type: constants.GET_OLO_CONFIGURATION_SUCCESS, data });
    return data;
  } catch (err) {
    yield put({ type: constants.SET_OLO_CONFIGURATION_DELETE_DATA_IS_LOADING, isLoading: false });
    yield put({ type: constants.ERROR_OCCURRED });
    return err;
  }
}

function* saveOloConfiguration({ payload }) {
  try {
    yield put({ type: constants.SET_OLO_CONFIGURATION_DATA_IS_LOADING, isLoading: true });
    yield put({ type: constants.SAVING_CONFIGURATION });
    const data = yield call(api.saveConfiguration, payload.toJS());
    yield put({ type: constants.SET_OLO_CONFIGURATION_DATA_IS_LOADING, isLoading: false });
    yield put({ type: constants.CONFIGURATION_SAVE_SUCCESS });
    yield put({ type: constants.GET_OLO_CONFIGURATION_SUCCESS, data });
    return data;
  } catch (err) {
    yield put({ type: constants.SET_OLO_CONFIGURATION_DATA_IS_LOADING, isLoading: false });
    yield put({ type: constants.CONFIGURATION_SAVE_SUCCESS });
    yield put({ type: constants.ERROR_OCCURRED });
    return err;
  }
}

function* oloConfigurationSaga() {
  yield takeLatest(constants.GET_CONFIGURATION, getOloConfiguration);
  yield takeLatest(constants.DELETE_CONFIGURATION, deleteOloConfiguration);
  yield takeLatest(constants.SAVE_CONFIGURATION, saveOloConfiguration);
}

export default oloConfigurationSaga;
