import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { DropdownWrapper, InputWrapper } from '@como/web-hub-components';
import { Icon } from 'semantic-ui-react';
import ToggleButton from '../../../common/components/ToggleButton/ToggleButton';
import * as constants from '../oloConfiguration.constants';
import * as actions from '../oloConfiguration.actions';

class OloConfigurations extends React.PureComponent {

  // eslint-disable-next-line no-undef
  static propTypes = {
    oloConfigurationData: PropTypes.object,
    validationErrors: PropTypes.object,
    formatMessage: PropTypes.func,
    automationIdPrefix: PropTypes.string,
    onFieldChange: PropTypes.func,
    intl: PropTypes.object,
    actions: PropTypes.object,
  }
  constructor(props) {
    super(props);
    this.state = {
      expandRedirectUris: true,
    };
    this.getApiKeysOptions = this.getApiKeysOptions.bind(this);
  }
  getPointShopWalletOptions() {
    return [
      { key: 1, text: 'Points', value: 'Points' },
      { key: 2, text: 'Credit', value: 'Budget' },
      { key: 3, text: 'None', value: 'None' }
    ];
  }
  getApiKeysOptions() {
    return this.props.oloConfigurationData.get(constants.APIKEY_DATA).map((apiKey) => {
      return { key: apiKey.get(constants.APIKEY_DATA_ID), text: `${apiKey.get(constants.APIKEY_DATA_NAME)} ${apiKey.get(constants.APIKEY_DATA_DEFAULT) ? ' (Default)' : ''}`, value: apiKey.get(constants.APIKEY_DATA_ID) };
    });
  }


  render() {
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>
        <div className="settings-row expand-area-controls ">
          <div className="settings-column new-huge">
            <div className="expandable-settings-area">
              <span className="expandable-area-title">
                {formatMessage({ id: `${this.props.automationIdPrefix}.redirect-uris-heading` })}
              </span>
              <div className="expandable-area-control push-left">
                <Icon
                  onClick={() => this.setState({ expandRedirectUris: !this.state.expandRedirectUris })}
                  data-automation-id={`${this.props.automationIdPrefix}.expand-branding-arrow`}
                  className="down-arrow"
                  name={`angle ${!this.state.expandRedirectUris ? 'up' : 'down'}`}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.expandRedirectUris &&
          <div className="olo-setting template-wrapper">
            {this.props.oloConfigurationData.get(constants.REDIRECT_URIS).map((url, index) => (
              <div key={index} className="olo-setting-content">
                <div className="olo-setting-row">
                  <div className="olo-setting-input-wrapper">
                    <InputWrapper
                      type="text"
                      prefix={`${this.props.automationIdPrefix}.redirect-uri`}
                      onChange={(e, target) => this.props.actions.redirectUrIFieldChange(index, target.value)}
                      value={url}
                      error={this.props.validationErrors ? this.props.validationErrors.getIn([constants.REDIRECT_URIS, index]) : null}
                    />
                  </div>
                  <Icon
                    className="como-ic-delete menu-delete-icon"
                    onClick={(e, target) => this.props.actions.redirectUrIFieldChange(index, null)}
                    data-automation-id={`${this.props.automationIdPrefix}.delete-item`}
                  />
                </div>
              </div>
            ))}
            <div className="add-custom-menu-item">
              <a
                role="button"
                className="condition-link add-condition"
                onClick={() => this.props.actions.addRedirectUrIField()}
                data-automation-id={`${this.props.automationIdPrefix}.add-redirect-uri`}
              >
                <Icon className="como-ic-plus-in-circle" size="large"/>
                {formatMessage({ id: `${this.props.automationIdPrefix}.add-redirect-uri` })}
              </a>
            </div>
          </div>}
        <div className="olo-setting-input-wrapper small-margin-bottom">
          <div className="olo-setting-input-label">
            {formatMessage({ id: `${this.props.automationIdPrefix}.logo-url` })}
          </div>
          <InputWrapper
            name={constants.LOGO_URL}
            type="text"
            prefix={`${this.props.automationIdPrefix}.logo-url`}
            onChange={(e, target) => this.props.actions.configurationFieldChange(target.name, target.value, true)}
            value={this.props.oloConfigurationData.get(constants.LOGO_URL)}
            error={this.props.validationErrors ? this.props.validationErrors.get(constants.LOGO_URL) : null}
          />
        </div>
        <div className="olo-setting-input-wrapper">
          <div className="olo-setting-input-label">
            {formatMessage({ id: `${this.props.automationIdPrefix}.terms-of-use` })}
          </div>
          <InputWrapper
            name={constants.TERMS_OF_USE}
            type="text"
            prefix={`${this.props.automationIdPrefix}.terms-of-use`}
            onChange={(e, target) => this.props.actions.configurationFieldChange(target.name, target.value, true)}
            value={this.props.oloConfigurationData.get(constants.TERMS_OF_USE)}
            error={this.props.validationErrors ? this.props.validationErrors.get(constants.TERMS_OF_USE) : null}
          />
        </div>
        <div className="olo-setting-input-wrapper">
          <div className="olo-setting-input-label">
            {formatMessage({ id: `${this.props.automationIdPrefix}.privacy-policy` })}
          </div>
          <InputWrapper
            name={constants.PRIVACY_POLICY}
            type="text"
            prefix={`${this.props.automationIdPrefix}.privacy-policy`}
            onChange={(e, target) => this.props.actions.configurationFieldChange(target.name, target.value, true)}
            value={this.props.oloConfigurationData.get(constants.PRIVACY_POLICY)}
            error={this.props.validationErrors ? this.props.validationErrors.get(constants.PRIVACY_POLICY) : null}
          />
        </div>
        <div className="olo-setting-input-wrapper">
          <div className="olo-setting-input-label">
            {formatMessage({ id: `${this.props.automationIdPrefix}.olo_base_url` })}
          </div>
          <InputWrapper
            name={constants.BASE_URL}
            type="text"
            prefix={`${this.props.automationIdPrefix}.olo_base_url`}
            onChange={(e, target) => this.props.actions.configurationFieldChange(target.name, target.value, true)}
            value={this.props.oloConfigurationData.get(constants.BASE_URL)}
            error={this.props.validationErrors ? this.props.validationErrors.get(constants.BASE_URL) : null}
          />
        </div>
        <div className="olo-setting" style={{ display: 'flex' }}>
          <div className="olo-setting-content" style={{ width: '33%' }}>
            <div className="olo-setting-input-label">
              {formatMessage({ id: `${this.props.automationIdPrefix}.apikey` })}
            </div>
            <DropdownWrapper
              disabled={false}
              options={this.getApiKeysOptions().toJS()}
              onSelectOption={(e, target) => this.props.actions.configurationFieldChange(target.name, target.value, false)}
              placeholder={this.props.formatMessage({ id: `${this.props.automationIdPrefix}.select-api-key` })}
              index={0}
              allowAdditions={false}
              search={false}
              name={constants.APIKEY}
              type="text"
              prefix={`${this.props.automationIdPrefix}.${constants.APIKEY}`}
              value={this.props.oloConfigurationData.get(constants.APIKEY)}
              error={this.props.validationErrors ? this.props.validationErrors.get(constants.APIKEY) : null}
            />
          </div>
          <div className="olo-setting-content" style={{ width: '33%' }}>
            <div className="olo-setting-input-label">
              {formatMessage({ id: `${this.props.automationIdPrefix}.${constants.POINT_SHOP_WALLET}` })}
            </div>
            <DropdownWrapper
              disabled={false}
              options={this.getPointShopWalletOptions()}
              onSelectOption={(e, target) => this.props.actions.configurationFieldChange(target.name, target.value)}
              placeholder={this.props.formatMessage({ id: `${this.props.automationIdPrefix}.select-point-shop-wallet` })}
              index={0}
              allowAdditions={false}
              search={false}
              name={constants.POINT_SHOP_WALLET}
              type="text"
              prefix={`${this.props.automationIdPrefix}.${constants.POINT_SHOP_WALLET}`}
              value={this.props.oloConfigurationData.get(constants.POINT_SHOP_WALLET)}
            />
          </div>
          <div className="olo-setting-content" style={{ width: '33%' }}>
            <div className="olo-setting-input-label">
              {formatMessage({ id: `${this.props.automationIdPrefix}.businessTimeZone` })}
            </div>
            <InputWrapper
              disabled
              name={constants.TIME_ZONE}
              type="text"
              prefix={`${this.props.automationIdPrefix}.businessTimeZone`}
              onChange={(e, target) => this.props.actions.configurationFieldChange(target.name, target.value)}
              value={this.props.oloConfigurationData.get(constants.TIME_ZONE)}
            />
          </div>
        </div>
        <div className="olo-setting" style={{ display: 'flex' }}>
          <div className="olo-setting-content" style={{ width: '33%' }}>
            <div className="olo-setting-input-label">
              {formatMessage({ id: `${this.props.automationIdPrefix}.${constants.IS_BIRTHDAY_FIELD_REQUIRED}` })}
            </div>
            <ToggleButton
              name={constants.IS_BIRTHDAY_FIELD_REQUIRED}
              data-automation-id={`${this.props.automationIdPrefix}.${constants.IS_BIRTHDAY_FIELD_REQUIRED}`}
              automationId={`${this.props.automationIdPrefix}.${constants.IS_BIRTHDAY_FIELD_REQUIRED}`}
              id={`${this.props.automationIdPrefix}.${constants.IS_BIRTHDAY_FIELD_REQUIRED}.id`}
              onChange={() => {
                this.props.onFieldChange(constants.IS_BIRTHDAY_FIELD_REQUIRED, !this.props.oloConfigurationData.get(constants.IS_BIRTHDAY_FIELD_REQUIRED));
              }}
              enabled={this.props.oloConfigurationData.get(constants.IS_BIRTHDAY_FIELD_REQUIRED)}
            />
          </div>
          <div className="olo-setting-content" style={{ width: '33%' }}>
            <div className="olo-setting-input-label">
              {formatMessage({ id: `${this.props.automationIdPrefix}.${constants.MERGE_MODIFIERS_COSTS}` })}
            </div>
            <ToggleButton
              name={constants.MERGE_MODIFIERS_COSTS}
              data-automation-id={`${this.props.automationIdPrefix}.${constants.MERGE_MODIFIERS_COSTS}`}
              automationId={`${this.props.automationIdPrefix}.${constants.MERGE_MODIFIERS_COSTS}`}
              id={`${this.props.automationIdPrefix}.${constants.MERGE_MODIFIERS_COSTS}.id`}
              onChange={() => {
                this.props.onFieldChange(constants.MERGE_MODIFIERS_COSTS, !this.props.oloConfigurationData.get(constants.MERGE_MODIFIERS_COSTS));
              }}
              enabled={this.props.oloConfigurationData.get(constants.MERGE_MODIFIERS_COSTS)}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

const withConnect = connect(null, mapDispatchToProps);
export default compose(
  withConnect
)(injectIntl(OloConfigurations));
